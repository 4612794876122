.street-map {
    min-height: 470px;
    width: 100%;
    /*
    height: auto;
    width: auto;
    position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    */
}
