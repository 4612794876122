.city-selector {
    height: 4rem;
    width: 80vw;
    max-width: 400px;
    margin: 0 auto;
    position: relative;
        z-index: 10;
    font-family: Raleway, sans-serif;
    font-size: 1.8rem;
}
