@import './pages/**/*.css';
@import './components/**/*.css';

html { box-sizing: border-box; }
*, *::before, *::after { box-sizing: inherit; }
html, body { height: 100%; margin: 0; padding: 0; overflow: hidden; }



html {

}

body {
    font: 300 1.4rem/2rem Muli, sans-serif;
}
strong {
    font-weight: 700;
}

h1 {
    font: normal 3rem/4rem 'Cherry Swash', serif;
}

a {
    color: #FA0076;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

p {
    margin: 0.5rem 0;
}

hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin: 2rem 0;
}

/* ----------- */

$header-height: 4rem;
$header-gutter: 1rem;
$header-half-gutter: 0.5rem;
$header-icon-size: 2rem;
$main-gutter: 1rem;
$main-half-gutter: 0.5rem;
$main-double-gutter: 2rem;
$header-gutter-plus-main-gutter: 5rem;

$action-color: rgba(255, 187, 187, 1);
$background-color: rgba(246, 194, 205, 0.9);
$negative-font-color: #ffffff;

$box-shadow: 0 0 10px #aaaaaa;

$subtle-transition-time: 0.25s;

/* ----------- */

body > main {
    position: relative;
        z-index: 1000;
    height: 0;
    transform: translate(150%, 0);

    &.page {
        transform: translate(0, 0) !important;
    }

    .city-page {
        position: absolute;
            top: 0;
            left: auto;
            right: 0;
            bottom: 0;
            z-index: 1010;
        width: 30rem;
        height: 100vh;
        padding: $main-gutter $main-gutter 0;
        text-align: left;
        overflow-y: auto;
        transition: transform $subtle-transition-time;
    }
}

body > footer {
    position: absolute;
        left: 1rem;
        bottom: 1rem;
    text-align: left;
    font-family: Arial;
    font-size: 1.1rem;
    line-height: 1.3rem;
    color: white;
    text-shadow: 0 0 3px black;

    a {
        color: white;
    }
}

/* ----------- */

body.page .nav-map {
    transform: translateX(-15rem);
}

.nav-map {
    height: auto;
    width: auto;
    position: fixed !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    transform: translateX(0);
    transition: transform $subtle-transition-time;
}
    .leaflet-tooltip {
        font-family: Muli, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    }
    .leaflet-marker-icon {
        width: 0;
        height: 0;
        padding: 41px 25px 0 0;
        background-image: url('images/leaflet-icon.png');
        background-size: auto;

        &.active,
        &:hover {
            background-image: url('images/leaflet-icon-active.png');
            z-index: 9999 !important;
        }
    }

.city-page {
    box-shadow: $box-shadow;
}

/* ----------- */

.logo-link {
    position: absolute;
        top: 1rem;
        left: 1rem;
        z-index: 1002;
    margin: 0;
    text-align: center;
    box-shadow: $box-shadow;

    > a {
        display: block;
        height: 4rem;
        padding: 0 1rem;
        background-color: $background-color;
        border-radius: 4px;
        color: white;
        text-decoration: none !important;
    }
}

.instagram {
    display: block;
    height: 4rem;
    width: 4rem;
    padding: 0.5rem;
    background-image: url('images/icon-instagram.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 3rem 3rem;
    background-color: $background-color;
    border-radius: 7px;
    box-shadow: $box-shadow;
}
.instagram-main {
    position: fixed;
        right: 1rem;
        bottom: 1rem;
    transition: transform $subtle-transition-time;

    body.page & {
        transform: translate(-30rem, 0);
    }
}
.instagram-city {
    position: fixed;
        top: 100vh;
        right: 31rem;
        z-index: 1002;
    margin-top: -5rem;
}
.go-back {
    position: fixed;
        top: 1rem;
        right: 31rem;
        z-index: 1002;
    height: 4rem;
    width: 4rem;
    margin-bottom: -4rem;
    background-color: $background-color;
    border-radius: 7px;
    color: white;
    font-size: 2.5rem;
    line-height: 4rem;
    text-align: center;
    box-shadow: $box-shadow;
    cursor: pointer;
    transition: transform $subtle-transition-time;

    &::before {
        content: '\2AF7';
    }
}

.note {
    display: block;
    color: #333333;
    font-size: 75%;
    font-weight: normal;
}

/* ----------- */

@custom-media --small (max-width: 600px);
@custom-media --portrait (orientation: portrait);

@media (--small) and (--portrait) {

    .logo-link {
        right: 1rem;
    }

    body.page {

        .nav-map {
            transform: translateY(-15rem);
        }

        .instagram-main {
            transform: translate(0, 0);
            position: absolute;
                top: 50vh;
                right: 1rem;
                z-index: 1050;
            margin-top: -5rem;
        }
    }

    main.page {
        transform: translate(0, 100%);

        .city-page {
            top: 50vh;
            left: 0;
            width: 100vw;
            height: 50vh;

            &::before {
                content: '';
                position: fixed;
                    top: 50vh;
                    left: 0;
                    right: 0;
                    z-index: 1000;
                height: 4rem;
                background: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 0.75) 50%,
                    rgba(255, 255, 255, 0) 100%
                    );
            }

            h1.city-title,
            .place-list-item.on {
                position: relative;
                    z-index: 1000;
            }
        }

        .go-back {
            position: absolute;
                top: 50vh;
                left: 1rem;
                z-index: 1050;
            margin-top: -5rem;
        }

        .instagram-city {
            position: absolute;
                bottom: 1rem;
                right: 1rem;
                z-index: 1050;
            margin-top: -5rem;
        }
    }
}

html[data-engine="webkit-mobile"] {

    /* yuck */
    .city-page {
        padding-bottom: 60px !important;
    }
}
