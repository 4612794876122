%clearfix::after {
    display: block;
    content: '';
    clear: both;
}

main {
    text-align: justify;
    
    .city-page {
        background-color: rgba(255, 255, 255, 1);
        padding: 0 !important;

        h1 {
            padding: 1.5rem 1rem;
            margin: -1rem -1rem 0;
            line-height: 1em;
            color: white;
            background-color: rgba(246, 194, 205, 1);

            &.place-title {
                font-family: Muli, 'Helvetica Neue', Arial, Helvetica, sans-serif;
                font-size: 2.5rem;
                font-weight: 700;
            }
        }
    }
}

.city-summary {
    margin: 1rem;
}

.google-maps-widget {
    @extend %clearfix;
    position: relative;
    margin-top: 1.5rem;
    padding-top: 3rem;
    background-image: url('images/google-maps-logo.png');
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 2.5rem;

    .note {
        position: absolute;
            top: 0;
            right: 0;
        font-size: 1.25rem;
        line-height: 2.5rem;
        color: #777777;
    }

    a {
        display: block;
        float: left;
        width: 50%;
        margin-top: 0.5rem;
        font-family: Muli, sans-serif;
        font-size: 2rem;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        text-decoration: none !important;

        &::before {
            content: '';
            display: block;
            margin: 0 auto;
            width: 4.5rem;
            height: 4.5rem;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 4.5rem;
            background-image: url('images/be-social-icon.png');
            border-radius: 4.5rem;
        }

        &:first-child {
            clear: left;
        }
    }
}

.place-list {
    display: block;
    list-style: none;
    padding: 0;
    margin: -2rem 0 0;
}
    .place-list-item {
        padding: 1rem;
        background-color: rgb(255, 255, 255);
        cursor: pointer;
        transition: transform 0.25s, background-color 0.5s;

        &.on {
            background-color: rgb(238, 238, 242);
        }

        &:hover {
            background-color: rgb(252, 237, 241);
        }
    }
        .place-title {
            display: block;
            font-size: 2rem;
            font-weight: bold;
            line-height: 2.5rem;
            color: #FA0076;
        }
        .place-subtitle {
            opacity: 0.55;
            white-space: nowrap;
        }
        .place-blurb > *:not(:first-child) {
            margin-top: 0.5rem;
        }
        .place-blurb-google,
        .place-blurb-be,
        .place-website,
        .place-phone,
        .place-address {
            display: block;
        }
        .place-blurb-be,
        .place-website,
        .place-phone,
        .place-address {
            background-position: left 0.25em;
            background-repeat: no-repeat;
            background-size: 1em;
            padding-left: 1.5em;
            background-image: url('images/bullet-be.svg');
        }
        .place-phone,
        .place-address {
            float: left;
            clear: left;
            padding-right: 1rem;
        }
        .place-website,
        .place-phone {
            padding-top: 1rem;
            padding-bottom: 1rem;
            background-position: left center;
        }

        .place-blurb-google {
            font-style: italic;
        }
        .place-blurb-be {
            background-image: url('images/bullet-be.svg');
        }
        .place-website {
            display: inline-block;
            padding-right: 1rem;
            background-image: url('images/bullet-link.svg');

            &:empty {
                height: 1.25rem;
                padding: 0;
                background-image: none;
            }
        }
        .place-phone {
            background-image: url('images/bullet-phone.svg');
        }
        .place-address {
            background-size: 1.25em;
            background-image: url('images/bullet-google-maps.png');
        }
        .place-details {
            @extend %clearfix;
            transform: scale(1, 0);
            transform-origin: top center;
            transition: transform 0.25s;

            .place-list-item.on & {
                margin-bottom: 0;
                transform: scale(1, 1);
            }
        }
